import React from 'react'
import { graphql } from "gatsby"
import { Link } from 'gatsby'

// import Header from '@components/Header'
import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import { Flex, Br } from '@components/Grid'
import { Text } from '@components/Text'
import Card from '@components/Card'

import { withI18next } from '../i18n'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 — Not found" />

    {/* <Header /> */}

    <Flex p={6} justifyContent="center">
      <Card>
        <Text textAlign="center" as="h2" mb={3} fontWeight="bold" fontSize={7}>
          4😔4
        </Text>
        <Text mb={3} textAlign="center">
          We couldn’t find what you were looking for
          <Br />
          <Link to="/">Take me home.</Link>
        </Text>
      </Card>
    </Flex>
    <Text />
  </Layout>
)

export default withI18next()(NotFoundPage)

export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...LocaleFragment
    }
  }
  `
  